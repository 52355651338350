import React, { useState, useEffect } from "react";
import { Formik, Field, Form as FormikForm, FormikHelpers } from 'formik';
import "./BookingForm.scss";
import { Form, Button, Row } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import * as Yup from 'yup';
import Calendar from 'react-calendar';
import './Calendar.css';
import { ApiService } from "../../services/ApiService";
import moment from 'moment';
import ErrorModal from "../modals/ErrorModal";
import DuplicateModal from "../modals/DuplicateModal";
import { UtilitiesService } from "../../services/UtilitiesService";

const _ = require("lodash");

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

interface IObjectKeys {
    [key: string]: string | number;
}

interface Location {
    id: number;
    label: string;
    provAbbr: string;
    urlName: string;
}

interface Fields {
    type: number;
    location: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    birthMonth: number;
    birthDay: number;
    birthYear: number;
    reminder: boolean;
    onBehalf: boolean;
    guardianFirst: string;
    guardianLast: string;
    additionalInfo: string;
    time: string;
    date: string;
}

interface DuplicateAppointment {
    ID: number;
    Location: string;
    Start: string,
    PatientID: number
}


// RegEx for phone number validation
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("*First Name is required"),
    lastName: Yup.string().required("*Last Name is required"),
    email: Yup.string().email("*Must be a valid email address").required("*Email is required"),
    phone: Yup.string().matches(phoneRegExp, "*Phone number is not valid").required("*Phone number required"),
    time: Yup.string().required("*Please select an appointment time")
});

const BookingForm = ({ title, type, acknowledgements }: { title: string, type: string, acknowledgements: string[] }) => {

    const [typeID, setTypeID] = useState(0);
    const [apptType, setApptType] = useState('');
    const [locations, setLocations] = useState<{ [key: string]: any[] }>({});
    const [indexedLocations, setIndexedLocations] = useState<{ [key: string]: Location }>({});
    const [selectedLocation, setSelectedLocation] = useState(0);
    const [disabledDates, setDisabledDates] = useState<number[]>([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [availableTimes, setAvailableTimes] = useState([]);
    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [apiService, setApiService] = useState<ApiService | null>(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const [duplicateAppt, setDuplicateAppt] = useState<DuplicateAppointment | null>(null);
    const [currentAppt, setCurrentAppt] = useState('');
    const [formData, setFormData] = useState<Fields | null>(null);
    const [minBirthYear, setMinBirthYear] = useState(0);
    const [buttonPressed, setButtonPressed] = useState(false);

    const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: any): void => {

       const locationID = parseInt(e.target.value);
        setSelectedLocation(locationID);

       if (locationID) {
            fetchDates(locationID);
       }

       setFieldValue("location", locationID);
    }

const fetchAppointmentTypes = () => {

        const uri =  "/api/booking/get-available-types";
        apiService?.get(uri)
         .then(async response => {

            const string = await response.text();

            var typeID:number = 0;
    
            if (string !== "") {
                const json = JSON.parse(string);
                if(Array.isArray(json)){
                    json.forEach((element: { id: number, type: string; }) => {
                        if (element.type === type) {
                            typeID = element.id;

                            setTypeID(typeID);
                            setApptType(element.type.toLowerCase());
                        }
                    });
                }

                fetchLocations(typeID);
            }
        });
        
    };

    const fetchLocations = (typeID: number) => {
        const uri = "/api/booking/get-available-locations/" + typeID;

        apiService?.get(uri)
        .then(async response => {
            const string = await response.text();

            if (string !== "") {
              const json = JSON.parse(string);

              let reformatted:any = {};
              let indexedLocations:any = {};

              json.forEach((element: {id: number, label: string, province: string, provAbbr: string, urlName: string}) => {
                if (!reformatted[element.province]) {
                    reformatted[element.province] = [];
                }
                reformatted[element.province].push({
                    id: element.id,
                    label: element.label
                });

                indexedLocations[element.id] = {
                    id: element.id,
                    label: element.label,
                    provAbbr: element.provAbbr.toLocaleLowerCase(),
                    urlName: element.urlName
                }

              })
              
              setLocations(reformatted);
              setIndexedLocations(indexedLocations);
            }
            
        });
    };

    const fetchDates = (locationID: number) => {
        
        const uri = "/api/booking/get-available-dates/" + typeID + "/" + locationID;

        apiService?.get(uri)
        .then(async response => {
            const string = await response.text();

            if (string !== "") {
              const response = JSON.parse(string);

              var i:number;
              var disabledDates:number[] = [];

              for(i = 0; i < response.disabledDates.length; i++) {
                const date = convertStringToDate(response.disabledDates[i].date).getTime();
                disabledDates.push(date);
              }

              setDisabledDates(disabledDates);
              setMinDate(convertStringToDate(response.minDate.date));
              setMaxDate(convertStringToDate(response.maxDate.date));
              setSelectedDate(convertStringToDate(response.defaultDate.date));

              fetchTimes(response.defaultDate.date, locationID);
             
            }
        });
    }

    const fetchTimes = (date: string, location: number) => {

        const uri = "/api/booking/get-available-times/" + typeID + "/" + location + "/" + date;    

        apiService?.get(uri)
        .then(async response => {
            const string = await response.text();

            if (string !== "") {
              const times = JSON.parse(string);
              setAvailableTimes(times);
            }
        });
    }

    const handleCalendarChange = (value: Value, setFieldValue: any)=> {

        if (value) {
            const formattedDate = getFormattedDate(value);
            const newDay = convertStringToDate(formattedDate);

            fetchTimes(formattedDate, selectedLocation)
            setFieldValue("date", formattedDate);
            setSelectedDate(newDay);

        }
                
    }

    const getFormattedDate = (date: any) => {
        return moment(date).format('YYYY-MM-DD');
    }

    const convertStringToDate = (dateString: string) => {
        return moment(dateString, 'YYYY-MM-DD').toDate();
    }



    const handleCheckboxChange = () => {
        let checked = true;
        let checkboxes = document.querySelectorAll<HTMLInputElement>('#acknowledgements input[type=checkbox]');

        checkboxes.forEach(function(input){
            if(!input.checked){
                checked = false;
            }
        })

        const submitBtn = document.querySelector('button[type=submit]');

        if(submitBtn){
            submitBtn.classList.toggle('acknowledgements-complete', checked);
        }
    }

    const renderOptions = (options: any) => {
        return options.map((option:Location) => {
          return (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          );
        });

      };

    const closeModal = () => {
        setIsErrorModalOpen(false);
        setIsDuplicateModalOpen(false);
        setButtonPressed(false);
    };

    const moveAppt = () => {
        setButtonPressed(true);

        let data = _.clone(formData);
        data.moveDuplicateAppointment = duplicateAppt?.ID;

        const uri = "/api/booking/confirm";

        apiService?.post(uri, data)
        .then(async response => {
            const string = await response.text();
            const resp = string === "" ? {} : JSON.parse(string);

            if (resp.hasOwnProperty('Success')) {
                handleSuccessResponse(resp);
                apiService.clearToken();
            }

        })
    }

    const handleSuccessResponse = (resp: any) => {
        if (resp.Success) {

            const locationData = indexedLocations[selectedLocation];
            const province = locationData.provAbbr;
            const urlName = locationData.urlName;

            const remoteBookingID = resp.RemoteBookingID;
            const localBookingID = resp.LocalBookingID;
            const path = '/confirmation/' + apptType + '/' + province + '/' + urlName;

            window.sessionStorage.setItem('bookingConfirmed', '1');
            window.sessionStorage.setItem('remoteBookingID', remoteBookingID);
            window.sessionStorage.setItem('localBookingID', localBookingID);

            const href = UtilitiesService.appendUTMParamsToHref(path);
            window.location.href = href;

        } else {
            setIsErrorModalOpen(true);
        }
    }

    const init = () => {
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 95;
        setMinBirthYear(minYear);
    }

    useEffect(() => {

        // remove token that was already used to book an appointment
        if (window.sessionStorage.getItem('bookingConfirmed')) {
            window.sessionStorage.clear();
        }

        const initialize = async () => {
            init();
            return ApiService.create();
        }

        if (apiService) {
            fetchAppointmentTypes();
        } else {

            initialize().then(service => {
                setApiService(service);
            });
        
        }

    }, [apiService]);

    return (

        <div>
            <h4>{title} Appointment Booking</h4>
            <Formik
                initialValues={{
                    type: typeID,
                    location: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    birthMonth: 1,
                    birthDay: 1,
                    birthYear: minBirthYear,
                    reminder: true,
                    onBehalf: false,
                    guardianFirst: '',
                    guardianLast: '',
                    additionalInfo: '',
                    time: '',
                    date: ''

                }}
                validationSchema={validationSchema}
                onSubmit={(
                    values: Fields,
                    { setSubmitting, resetForm }: FormikHelpers<Fields>

                ) => {
                    setButtonPressed(true);

                    const birthMonth = String(values.birthMonth).padStart(2, '0');
                    const birthDay = String(values.birthDay).padStart(2, '0');
                    const birthYear = values.birthYear ? values.birthYear : minBirthYear;

                    let data = _.clone(values);
                    data.type = typeID;
                    data.birthdate = birthYear + '-' + birthMonth + '-' + birthDay;
                    data.date = getFormattedDate(selectedDate);
                    data.acknowledge = 1;

                    const uri = "/api/booking/confirm";

                    apiService?.post(uri, data)
                        .then(async response => {
                            const string = await response.text();
                            const resp = string === "" ? {} : JSON.parse(string);

                            if (resp.hasOwnProperty('Success')) {
                                handleSuccessResponse(resp);
                                apiService.clearToken();
                            }

                            if (resp.hasOwnProperty('Duplicate') && resp.Duplicate) {
                                setIsDuplicateModalOpen(true);
                                setButtonPressed(false);
                                setDuplicateAppt(resp.Appointment);

                                const currentAppt = data.date + ' ' + data.time;

                                setCurrentAppt(currentAppt);
                                setFormData(data);
                            }

                            setSubmitting(false);
                            console.log(resp);
                            return resp;
                        });

                  
                }}
            >
                {({ values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting }) => (

                    <FormikForm>

                        <Field type="hidden" value={typeID} name="type" />

                        {title !== 'Kintec Anywhere' && (
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formLocation">
                                    <Form.Label>Location</Form.Label>
                                    <Form.Select
                                        name="location"
                                        onChange={(e) => {handleLocationChange(e, setFieldValue)}}>
                                            {(() => {
                                                let groupedOptions: any[] = [];

                                                groupedOptions = Object.keys(locations).map((group:string, index:number) => {
                                                    return (
                                                        <optgroup key={index+1} label={group}>
                                                          {renderOptions(locations[group])}
                                                        </optgroup>
                                                      );
                                                });

                                                groupedOptions.unshift(<option key='0' value=''>(Select one)</option>);

                                                return groupedOptions;
                                            })()}

                                    </Form.Select>
                                </Form.Group>
                            </Row>
                        )}


                        <Row className="mb-3">
                            <Form.Label as={Col} sm={12}>Date & Time</Form.Label>
                            <Form.Group as={Col} sm={12} md={6} controlId="dateTime">
                                <Calendar prev2Label={null} next2Label={null} calendarType="gregory" value={selectedDate} minDate={minDate} maxDate={maxDate} onChange={val => {handleCalendarChange(val, setFieldValue)}} tileDisabled={({ date }) => { return disabledDates.includes(date.getTime()) }} />
                            </Form.Group>
                            <Form.Group as={Col} sm={12} md={6}>

                                {(() => {
                                    if (availableTimes.length > 0) {
                                        let options: any = [];

                                        _.forEach((availableTimes), function (time: any) {
                                            options.push( <Col sm={12} md={6}><Form.Check
                                            type='radio'
                                            name='time'
                                            key={`time_${time.time}`}
                                            id={`time_${time.time}`}
                                            label={`${time.label}`}
                                            value={`${time.time}`}
                                            className="time"
                                            onChange={handleChange}
                                            /></Col>)
                                        })

                                        return (<Row id="date-options">{options}</Row>)
                                    }

                                    return <p>Sorry no times available on that date.</p>;
                                })()}

                            </Form.Group>
                            {errors.time ? (
                                    <Col sm={12} className="error-message">{errors.time}</Col>
                                ) : null}
                        </Row>


                        <h4>Patient Information</h4>

                        <Row className="mb-3">
                            <Form.Group as={Col} sm={12} md={6} controlId="formFirstName">
                                <Form.Label>Legal First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.firstName && errors.firstName ? "error" : undefined}
                                />
                                {touched.firstName && errors.firstName ? (
                                    <div className="error-message">{errors.firstName}</div>
                                ) : null}
                            </Form.Group>
                            <Form.Group as={Col} sm={12} md={6} controlId="formLastName">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.lastName && errors.lastName ? "error" : undefined}
                                />
                                {touched.lastName && errors.lastName ? (
                                    <div className="error-message">{errors.lastName}</div>
                                ) : null}
                            </Form.Group>
                            <Form.Group as={Col} sm={12} controlId="formEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.email && errors.email ? "error" : undefined}
                                />
                                {touched.email && errors.email ? (
                                    <div className="error-message">{errors.email}</div>
                                ) : null}
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Col sm={12} md={5}>
                                <Form.Label>Birthday</Form.Label>
                                <Row>
                                    <Col id="birthday-fields">
                                        <Form.Select
                                            name="birthMonth"
                                            value={values.birthMonth}
                                            onChange={handleChange}
                                        >
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </Form.Select>

                                        <Form.Select
                                            name="birthDay"
                                            value={values.birthDay}
                                            onChange={handleChange}
                                        >
                                            {(() => {
                                                let options = [];
                                                for (let i = 1; i <= 31; i++) {
                                                    options.push(<option key={i}>{i}</option>);
                                                }
                                                return options;
                                            })()}

                                        </Form.Select>

                                        <Form.Select
                                            name="birthYear"
                                            value={values.birthYear}
                                            onChange={handleChange}
                                        >
                                            {(() => {
                                                let options = [];
                                                const currentYear = new Date().getFullYear();

                                                for (let i = minBirthYear; i <= currentYear; i++) {
                                                    options.push(<option key={i}>{i}</option>);
                                                }
                                                return options;
                                            })()}
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Col>

                            <Form.Group as={Col} sm={12} md={3} controlId="formPhone">
                                <Form.Label>Contact Number</Form.Label>

                                <Form.Control
                                    type="text"
                                    name="phone"
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className={touched.phone && errors.phone ? "error" : undefined}
                                />
                                {touched.phone && errors.phone ? (
                                    <div className="error-message">{errors.phone}</div>
                                ) : null}
                            </Form.Group>
                            <Form.Group as={Col} sm={12} md={4} controlId="formReminder" className="reminder-container">
                                <Form.Check
                                    type="checkbox"
                                    name="reminder"
                                    checked={values.reminder}
                                    label="Receive reminder as text message"
                                    className="reminder"
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <div className="col">
                                <div className="guardian-container">
                                    <Form.Group controlId="formOnBehalf">

                                        <Form.Check
                                            type="checkbox"
                                            name="onBehalf"
                                            checked={values.onBehalf}
                                            label="Booking on behalf of a dependent"
                                            onChange={handleChange}
                                        />
                                    </Form.Group>

                                    <Row>
                                        <Form.Group as={Col} sm={12} md={6} controlId="formGuardianFirst">
                                            <Form.Label>Guardian's First Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="guardianFirst"
                                                value={values.guardianFirst}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={12} md={6} controlId="formGuardianLast">
                                            <Form.Label>Guardian's Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="guardianLast"
                                                value={values.guardianLast}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Form.Group>
                                    </Row>

                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Form.Group controlId="formAdditionalInfo">
                                <Form.Label>Anything else we should know? Additional comments or questions <span className="note">(Optional)</span></Form.Label>
                                <Form.Control as="textarea" name="additionalInfo" rows={3} value={values.additionalInfo} onChange={handleChange} onBlur={handleBlur} />
                            </Form.Group>
                        </Row>
                        <Row id="acknowledgements">
                            <Form.Group controlId="acknowledge">
                                {acknowledgements.map((acknowledge, i) => {
                                    let key = "acknowledge_" + i

                                    return (<Form.Check
                                        type="checkbox"
                                        label={<span dangerouslySetInnerHTML={{ __html: acknowledge }} />}
                                        key={key}
                                        id={key}
                                        onChange={handleCheckboxChange}
                                        required
                                    />)
                                })}
                            </Form.Group>
                        </Row>
                        <Row>
                            <div className="col">
                                <Button type="submit" className="btn btn-default" disabled={buttonPressed}>Confirm Appointment</Button>
                            </div>
                        </Row>
                    </FormikForm>)}


            </Formik>
            <ErrorModal isOpen={isErrorModalOpen} closeModal={closeModal} />

            {duplicateAppt &&
            <DuplicateModal isOpen={isDuplicateModalOpen} duplicateAppt={duplicateAppt.Start} currentAppt={currentAppt} apptType={apptType} moveAppt={moveAppt} closeModal={closeModal} buttonPressed={buttonPressed} />}


        </div>
    );
};

export default BookingForm;